import { Grid } from '@mui/material';
import foodApi from 'api/foodApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import ShopFilter from './pages/ShopFilter/ShopFilter';
import ShopProduct from './pages/ShopProduct/ShopProduct';
import ShopSearch from './pages/ShopSearch/ShopSearch';
import { selectFoodFilter, selectFoodList, shopFoodAction } from './shopFoodSlice';
import DOMPurify from 'dompurify';

export default function ShopFood(catid) {
	const dispatch = useAppDispatch();
	const filter = useAppSelector(selectFoodFilter);
	const foodList = useAppSelector(selectFoodList);
	const [categories, setCategories] = useState(null);
	console.log("catid",catid.catid);
	const navigate = useNavigate();
	const state = useLocation().state;
	console.log("state",state);

	//  const { id } = state?.id; //match.params;
	const [cid, setCid] = useState(catid.catid || "produse-noi");
	//const [cid, setCid] = useState(1);
console.log("cid",cid);
	useEffect(() => {

		foodApi.getFoods('/categories/all').then(x => setCategories(x));
		console.log("categ all:",categories);
	/*	if(cid) {
			console.log("ssa cis", cid);
			handleSubmitGetFood(2);
		//	const catFood =  foodApi.getFoods('/products/category/paste', filter);
		//	dispatch(shopFoodAction.fetchPizzasFood(catFood));
			//return;
		}*/
	   }, []);
	//get main food
	useEffect(() => {
		//const categ  = foodApi.getFoods('/categories/main',setCategories(categ));

		dispatch(shopFoodAction.fetchFoodList(filter));
		//handleSubmitGetFood(1);


	}, [dispatch, filter]);
	if(categories) {
	console.log("categ:",categories[0].slug);
}
	//get food follow params


	const handleSubmitGetFood = async (idx: number) => {
		if(categories) {
			if (idx<=categories.length+1) {
				//filter._page=1;
			console.log("idx",idx,categories[idx-1].slug);// `/products/id/${id}`
			const AllFood = await foodApi.getFoods('/products/category/'+categories[idx-1].slug, { _page: 1, _limit: 16});
			dispatch(shopFoodAction.fetchBurgersFood(AllFood));
			console.log("filter",filter,filter._page);
			return;
			}
		} else {
		switch (idx) {
			case 1:
				const burgersFood = await foodApi.getFoods('/products/category/produse-noi', filter);
				dispatch(shopFoodAction.fetchBurgersFood(burgersFood));
				break;
			case 2:
				const breadsFood = await foodApi.getFoods('/products/category/paste', filter);
				dispatch(shopFoodAction.fetchBreadsFood(breadsFood));
				break;
			case 3:
				const sandwichesFood = await foodApi.getFoods('/sandwiches', filter);
				dispatch(shopFoodAction.fetchSandWichesFood(sandwichesFood));
				break;
			case 4:
				const drinkFoods = await foodApi.getFoods('/drinks', filter);
				dispatch(shopFoodAction.fetchDrinksFood(drinkFoods));
				break;

			case 5:
				const pizzasFood = await foodApi.getFoods('/products/category/produse-noi', filter);
				dispatch(shopFoodAction.fetchPizzasFood(pizzasFood));
				break;
			default:
				return;
		}
	}
	};

	//get food follow price
	const handleSubmitPrice = (idx: number) => {
		switch (idx) {
			case 1:
				const newFilterUnder = { _page: 1, _limit: 16, price_lte: 100 };
				dispatch(shopFoodAction.setFilter(newFilterUnder));
				break;
			case 2:
				const newFilterUpTo = { _page: 1, _limit: 16, price_gte: 100 };
				dispatch(shopFoodAction.setFilter(newFilterUpTo));
				break;
			case 3:
				const newFilterUnderDown = { _page: 1, _limit: 16, price_lte: 50 };
				dispatch(shopFoodAction.setFilter(newFilterUnderDown));
				break;
			case 4:
				const newFilterTo = { _page: 1, _limit: 16, price_gte: 50, price_lte: 100 };
				dispatch(shopFoodAction.setFilter(newFilterTo));
				break;
			default:
				return;
		}
	};

	//get food follow vote
	const handleSubmitVote1 = () => {
		const newFilterUnder = { _page: 1, _limit: 16, rate_like: 5 };
		dispatch(shopFoodAction.setFilter(newFilterUnder));
	};

	const handleSubmitVote2 = () => {
		const newFilterUnder = { _page: 1, _limit: 16, rate_like: 4 };
		dispatch(shopFoodAction.setFilter(newFilterUnder));
	};

	const handleSubmitVote3 = () => {
		const newFilterUnder = { _page: 1, _limit: 16, rate_like: 3 };
		dispatch(shopFoodAction.setFilter(newFilterUnder));
	};

	//handle Search
	const onFoodSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const newFilterUnder = { _page: 1, _limit: 16, title_like: e.target.value };
		dispatch(shopFoodAction.setFilterWidthDebounce(newFilterUnder));
	};

	//show Food follow id
	const getFoodById = (id: string) => {
		navigate(`/shop/${id}`);
	};

	useEffect(() => {

		console.log("paste", cid);
		if(cid) {
			console.log("ssa cis", cid);
			if(categories) {
			const rt = categories.find(({ slug }) => slug === cid);
			if (rt!== undefined) {
				console.log("rt.id",rt);
			handleSubmitGetFood(rt.id);
			}
		}
		//	const catFood =  foodApi.getFoods('/products/category/paste', filter);
		//	dispatch(shopFoodAction.fetchPizzasFood(catFood));
			//return;
		}
	   }, [cid,categories]);
	

	return (
		<Grid container maxWidth="lg" sx={{ margin: '0 auto' }}>
			<Grid item xs={12} md={2}>
				<ShopFilter
					onSubmitFood={handleSubmitGetFood}
					onSubmitPrice={handleSubmitPrice}
					onSubmitVote1={handleSubmitVote1}
					onSubmitVote2={handleSubmitVote2}
					onSubmitVote3={handleSubmitVote3}
				/>
			</Grid>
			<Grid item xs={12} md={10}>
				<ShopSearch onFoodSearch={onFoodSearch} />
				<ShopProduct bestFood={foodList} getFoodById={getFoodById} />
			</Grid>
		</Grid>
	);
}


