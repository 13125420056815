import { Container, Grid } from '@mui/material';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import BusinessIcon from '@mui/icons-material/Business';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Box from '@mui/material/Box';
import './styles.scss';
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
	const navigate = useNavigate();
	return (
	<>
		<footer className="footer">
			<Container>
				<Grid container spacing={8}>
					<Grid item xs={12} sm={6} md={4}>
						<div className="footer__time">
							<div className="col">
								<span className="footer__time-day">Luni</span>
								<span className="footer__time-day">Marţi</span>
								<span className="footer__time-day">Miercuri</span>
								<span className="footer__time-day">Joi</span>
								<span className="footer__time-day">Vineri</span>
								<span className="footer__time-day">Sâmbătă</span>
								<span className="footer__time-day">Duminică</span>
							</div>
							<div className="col">
								<LinearScaleIcon className="footer__time-dots" />
								<LinearScaleIcon className="footer__time-dots" />
								<LinearScaleIcon className="footer__time-dots" />
								<LinearScaleIcon className="footer__time-dots" />
								<LinearScaleIcon className="footer__time-dots" />
								<LinearScaleIcon className="footer__time-dots" />
								<LinearScaleIcon className="footer__time-dots" />
							</div>
							<div className="col">
								<span className="footer__time-hours">10.00-20.00</span>
								<span className="footer__time-hours">10.00-20.00</span>
								<span className="footer__time-hours">10.00-20.00</span>
								<span className="footer__time-hours">10.00-20.00</span>
								<span className="footer__time-hours">10.00-20.00</span>
								<span className="footer__time-hours">12.00-20.00</span>
								<span className="footer__time-hours">12.00-20.00</span>
							</div>
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className="footer__contacts">
							<h4 className="footer__contact-title">Adresa</h4>

							<div className="footer__contact">
								<PhoneInTalkIcon className="footer__contact-icon" />
								<span className="footer__contact-txt"><a href="tel:0314250714" title="Sună-ne">0314250714</a></span>
							</div>

							<div className="footer__contact">
								<SpeakerNotesIcon className="footer__contact-icon" />
								<span className="footer__contact-txt">comenzi@pizzeriaclinceni.ro</span>
							</div>

							<div className="footer__contact">
								<BusinessIcon className="footer__contact-icon" />
								<span className="footer__contact-txt">Str. Principală, Comuna Clinceni 077025</span>
							</div>
							<div className="footer__contact footer__contact--icons">
								<FacebookIcon style={{ fill: '#2D88FF' }} />
								<TwitterIcon style={{ fill: '#5DA9DD' }} />
								<InstagramIcon style={{ fill: '#F56040' }} />
								<YouTubeIcon style={{ fill: '#FF0000' }} />
							</div>
							<Link to="/page/termeni-si-conditii">
							<div className="footer__contact">
							
								<span className="footer__contact-txt">Termeni si Conditii</span>
							</div>
							</Link>
							<Link to="/page/politica-de-confidentialitate">
							<div className="footer__contact">
							
								<span className="footer__contact-txt">Politica de Confidentialitate</span>
							</div>
							</Link>
							<a href="Meniu nutritional - die.pdf" target="_blank">
							<div className="footer__contact">
							
								<span className="footer__contact-txt">Gramaje, alergeni, ingrediente si valori nutritionale</span>
							</div>
							</a>
							<a href="menu-clinceni.pdf" target="_blank">
							<div className="footer__contact">
							<span className="footer__contact-txt">Meniu PDF</span>
							</div>
							</a>
						</div>
					</Grid>

					<Grid item xs={12} md={6} lg={4}>
						<div className="footer__map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2851.7014601165774!2d25.94160305002599!3d44.37772047900054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40adff2ef041d70f%3A0xa172449bc630ce76!2sPizzeria%20Clinceni!5e0!3m2!1sen!2sro!4v1669309236057!5m2!1sen!2sro"
								width="600"
								height="450"
								style={{ width: '100%', height: '100%', border: 0 }}
								loading="lazy"
								title="map"
							></iframe>
						</div>
					</Grid>
				</Grid>
			</Container>
 
		</footer>
		 <Box
      display="flex"
      justifyContent="center"

    >
  <div className="footer-bottom px-16 py-1 w-full  sm:flex justify-between items-center text-sm text-white">

        <span>
            		&copy; Copyright  {new Date().getFullYear()}&nbsp;|&nbsp;Website official <a href="https://pizzeriaclinceni.ro/" target="_blank">Pizzeria Clinceni</a>&nbsp;|&nbsp;Toate drepturile rezervate&nbsp;|&nbsp;Powered by <a href="https://interpersonal.ro/" target="_blank"><img src="upload/interpersonalmain.png.webp" className="center 
  bg-cover"
  alt=""
  sx={{ objectFit: "contain" }} /></a>
        </span>

	  </div>
	  </Box>
	  </>
	);
}

export default Footer;
